.brands-page {
    font-family: 'Roboto', sans-serif;
    color: #333;
  }
  
  header h1 {
    font-size: 2.5rem;
    color: #0056b3;
  }
  
  header p {
    font-size: 1.2rem;
    color: #666;
  }
  
  .info-card {
    background: #f9f9f9;
    border: 1px solid #ddd;
    transition: transform 0.2s ease-in-out;
  }
  
  .info-card:hover {
    transform: scale(1.05);
  }
  
  .info-card .card-title {
    font-size: 1.5rem;
    color: #0056b3;
  }
  
  .info-card .card-text, .info-card .list-group-item {
    font-size: 1rem;
    color: #444;
  }
  
  footer h3 {
    font-size: 2rem;
    color: #0056b3;
  }
  
  footer p {
    font-size: 1.2rem;
    color: #666;
  }
  
  footer .btn-primary {
    background-color: #0056b3;
    border-color: #0056b3;
    padding: 0.5rem 2rem;
    font-size: 1rem;
  }
  
  footer .btn-primary:hover {
    background-color: #004494;
    border-color: #004494;
  }